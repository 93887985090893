import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { UserService } from './user/user.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { iEntityFilterParams } from '../interfaces/entity-params';
import * as Sentry from "@sentry/angular";
import { Severity } from '@sentry/angular';

@Injectable()
export class FileManagerService {

  defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
  };

  defaultShoutout = "./assets/images/default-avatar/default-avatar-mt.png";

  constructor(private _http: HttpClient,
              private _userService: UserService) { }

  getImageSrc(fileId: number | string): string {      
         return environment.fileApiUrl + '/image/get/' + fileId + '?' +
        'token=' + this._userService.token + '&ngsw-bypass=true';
  }

  getDefaultAvatarSrc(fileId: number | string): string {
    if (fileId=== null){
      return this.defaultShoutout
    }else {       
      return this.getImageSrc(fileId)
    }
  }

  getAudioSrc(fileId: number | string): string {
    return environment.fileApiUrl + '/audio/get/' + fileId + '?' +
      'token=' + this._userService.token + '&ngsw-bypass=true';
  }

  // TODO Should union with getImageSrc
  getFileSrc(fileId: number | string): string {
    return environment.apiUrl + '/file/get?' +
      'token=' + this._userService.token + '&' +
      'id=' + fileId;
  }

  uploadReq(reqUrl: string, formData: FormData): Observable<any> {
    return this._http.post(reqUrl, formData).pipe(
      map((response: any) => {
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
      })
    );
  }

  uploadReqWithCounter(reqUrl: string, formData: FormData, sizeUpdateCallback: CallableFunction = () => {}): Observable<any> {
    return this._http.post(reqUrl, formData, {reportProgress: true, observe: 'events'}).pipe(
      map((event: any) => {
        if(event.type === HttpEventType.UploadProgress) {
          sizeUpdateCallback(event.loaded);
        }
        if(event.type === HttpEventType.Response) {
          let response = event.body;
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
        }
      })
    )
  }

  storageUpload(file: any, folderId?: number): Observable<any> {
    const reqUrl: string = `${environment.uploadApiUrl}/mediastorage/file/upload?token=${this._userService.token}&ngsw-bypass=true`;
    const formData: FormData = new FormData();
    formData.append('payload', file);
    if (folderId) formData.append('folder_id', ''+folderId);
    return this.uploadReq(reqUrl, formData);
  }

  storageUploadWithCounter(file: any, folderId?: number, sizeUpdateCallback: CallableFunction = () => {}): Observable<any> {
    const reqUrl: string = `${environment.uploadApiUrl}/mediastorage/file/upload?token=${this._userService.token}&ngsw-bypass=true`;
    const formData: FormData = new FormData();
    formData.append('payload', file);
    if (folderId) formData.append('folder_id', ''+folderId);
    return this.uploadReqWithCounter(reqUrl, formData, sizeUpdateCallback);
  }

  uploadFile(file: any): Observable<any> {
    const reqUrl: string = `${environment.uploadApiUrl}/file/upload?token=${this._userService.token}&ngsw-bypass=true`;
    const formData: FormData = new FormData();
    formData.append('payload', file);
    return this.uploadReq(reqUrl, formData);
  }

  uploadFileWithCounter(file: any, sizeUpdateCallback: CallableFunction = () => {}): Observable<any> {
    const reqUrl: string = `${environment.uploadApiUrl}/file/upload?token=${this._userService.token}&ngsw-bypass=true`;
    const formData: FormData = new FormData();
    formData.append('payload', file);
    return this.uploadReqWithCounter(reqUrl, formData, sizeUpdateCallback);
  }

  uploadVideo(file: any, folderId?: number): Observable<any> {
    const reqUrl: string = `${environment.uploadApiUrl}/video/upload?token=${this._userService.token}&ngsw-bypass=true`;
    const formData: FormData = new FormData();
    formData.append('payload', file);
    if (folderId) formData.append('folder_id', ''+folderId);
    return this.uploadReqWithCounter(reqUrl, formData);
  }

  uploadVideoWithCounter(file: any, folderId?: number, sizeUpdateCallback: CallableFunction = () => {}): Observable<any> {
    const reqUrl: string = `${environment.uploadApiUrl}/video/upload?token=${this._userService.token}&ngsw-bypass=true`;
    const formData: FormData = new FormData();
    formData.append('payload', file);
    if (folderId) formData.append('folder_id', ''+folderId);
    return this.uploadReqWithCounter(reqUrl, formData, sizeUpdateCallback);
  }

  delete(fileId: string): Observable<any> {
    const reqUrl = environment.apiUrl + '/image/delete?ngsw-bypass=true';

    let body = JSON.stringify({
      token: this._userService.token,
      id: fileId
    });

    return this._http.post(reqUrl, body, this.defaultHttpOptions).pipe(
      map((response: any) => {
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
      })
    );
  }

  getGeneratedFile(data: any, fileName: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getFiles(entityType: string = 'file', explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType + '/get';
    let bodyString;
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return  this._http.post(`${environment.apiUrl}/`+reqUrl, bodyString, requestOptions || this.defaultHttpOptions)
  }

  getVideoPreviewFields(fileId: number | string , requestOptions?: any ): Observable<any>  {
    const header =  environment.fileApiUrl + '/mediastorage/file/preview/get' + '?file_id=' + fileId 
    return  this._http.post(header , requestOptions)
  }

  getPreview(fileId: number | string , requestOptions?: any ): Observable<any>  {
    const header =  environment.fileApiUrl + '/video/preview/get' + '?file_id=' + fileId 
    return  this._http.post(header , requestOptions)
  }

  // image/get issues checker: Remove after tests of migration to new fileserver
  fileIssuesChecker(): void {
    window.document.addEventListener("error", (event: any) => {
      if (event?.target?.currentSrc?.includes("image/get")) {
        Sentry.captureMessage("Error image/get src: " + event.target.currentSrc, Severity.Error);
      }
    }, true);
  }
  //-----------------------------//
}
